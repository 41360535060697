<template>
  <div class="login">
    <div class="form">
      <h1 style="font-size:50px">Login</h1>
      <div class="box">
        <img class="img1" src="../assets/image/icons8-phone.png" alt />
        <el-input class=".el-input" v-model="loginInfo.phone" placeholder="Phone"></el-input>
      </div>
      <div class="box">
        <img class="img2" src="../assets/image/icons8-password.png" alt />
        <el-input
          class=".el-input"
          type="password"
          v-model="loginInfo.password"
          placeholder="password"
        ></el-input>
      </div>
      <!-- <div class="yzm">
        <div class="yz">
          <img class="img3" src="../assets/image/icons8-yzm.png" alt />
          <el-input class=".el-input" ref="myInp" v-model="inputVue" placeholder=" Note the case"></el-input>
        </div>

        <div class="img-canvas">
          <canvas id="canvas" :width="width" :height="height" @click="handleDraw"></canvas>
        </div>
      </div> -->
      <div class="login-but">
        <button class="l-bu" @click="userLogin">Login</button>
        &nbsp;
        <button class="l-bu" @click="sw">register</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { throttle } from "lodash";
export default {
  data() {
    return {
      loginyzm: "",
      inputVue: "",
      login: false,
      input: "",
      loginInfo: {
        password: "",
        phone: ""
      },

      allTexts: "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", // 字符串
      width: 120,
      height: 40,
      imgCode: "",
      imhCode1: ""
    };
  },
  watch: {
    login(newValue, oldValue) {
      ////console.log(1111);
      // this.imgCode = this.draw();
    }
  },
  mounted() {
    // 初始化绘制图片验证码
    // this.imgCode = this.draw();
  },
  methods: {
    // 需要节流的方法
    handleClick: throttle(function() {
      // 处理点击事件
    }, 1000), // 1000毫秒的节流

    sw() {
      this.$router.push("/register");
    },

    userLogin: throttle(function() {
      if (this.loginInfo.phone == "" || this.loginInfo.password == "") {
        this.$message.error("phone or password is null");
        return;
      }
      // if (this.inputVue != this.loginyzm) {
      //   this.$message.error("captcha error");
      //   return;
      // }

      axios
        .post("/puserSys/puser/login", this.loginInfo, {
          withCredentials: true
        })
        .then(res => {
          //console.log("userLogin", res.data);
          sessionStorage.setItem("tokenUrl", "");
          if (res.data.data) {
            sessionStorage.setItem("token", res.data.data.userid);
            sessionStorage.setItem("tokenUrl", res.data.data.Token);
            sessionStorage.setItem(
              "loginInfopassword",
              this.loginInfo.password
            );
            sessionStorage.setItem("phone", this.loginInfo.phone);
            this.$message.success("Login success");
            this.$router.push("/");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    }, 3000),
    logon: throttle(function() {
      if (this.logonInfo.phone == "" || this.logonInfo.password == "") {
        this.$message.error("password or phone or captcha is null");
        return;
      }
      if (this.logonInfo.phone.length < 3 || this.logonInfo.phone.length > 30) {
        this.$message.error("phone length is 1 to 30 digits");
        return;
      }

      if (
        this.logonInfo.password.length < 6 ||
        this.logonInfo.password.length > 10
      ) {
        this.$message.error("Password length is 6 to 10 digits");
        return;
      }
      //  ////console.log(this.logonInfo);
      axios
        .post("/puserSys/puser/logon", this.logonInfo)
        .then(res => {
          ////console.log(res);
          if ((res.data.msg = "Logon Success")) {
            this.$message.success("Registration successful, please log in");
            this.login = !this.login;
            //////console.log(res);
            return;
          }

          if ((res.data.msg = "User Already Exists")) {
            this.$message.error("User Already Exists");
            return;
          }
        })
        .catch(() => {});
    }, 3000),

    //todo 点击图片刷新
    handleDraw() {
      this.imgCode = this.draw();
    },
    handleDraw() {
      this.imgCode1 = this.draw();
    },
    //todo 产生随机数
    randomNum(min, max) {
      return parseInt(Math.random() * (max - min) + min);
    },
    //todo 产生随机颜色
    randomColor(min, max) {
      const r = this.randomNum(min, max);
      const g = this.randomNum(min, max);
      const b = this.randomNum(min, max);
      return `rgb(${r},${g},${b})`;
    },
    //todo 绘图
    draw() {
      const canvas = document.getElementById("canvas");
      const ctx = canvas.getContext("2d");
      //!! 1.填充背景颜色，背景颜色要浅一点
      ctx.fillStyle = this.randomColor(180, 230);
      // 填充的位置
      ctx.fillRect(0, 0, this.width, this.height);
      let imgCode = "";
      //!! 2.随机产生字符串，并且随机旋转
      for (let i = 0; i < 4; i++) {
        // 随机的四个字
        const text = this.allTexts[this.randomNum(0, this.allTexts.length)];
        imgCode += text;
        // 随机的字体大小
        const fontSize = 33;
        // 字体随机的旋转角度
        const deg = this.randomNum(-30, 30);
        ctx.font = fontSize + "px Arial";
        ctx.textBaseline = "top";
        ctx.fillStyle = this.randomColor(80, 150);
        ctx.save();
        ctx.translate(30 * i + 15, 15);
        ctx.rotate((deg * Math.PI) / 180);
        ctx.fillText(text, -15 + 5, -15);
        ctx.restore();
      }
      this.loginyzm = imgCode;
      //!! 3.随机产生5条干扰线
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height)
        );
        ctx.lineTo(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height)
        );
        ctx.strokeStyle = this.randomColor(180, 230);
        ctx.closePath();
        ctx.stroke();
      }

      //!! 4.随机产生40个干扰的小点
      for (let i = 0; i < 40; i++) {
        ctx.beginPath();
        ctx.arc(
          this.randomNum(0, this.width),
          this.randomNum(0, this.height),
          1,
          0,
          2 * Math.PI
        );
        ctx.closePath();
        ctx.fillStyle = this.randomColor(150, 200);
        ctx.fill();
      }
    }
  }
};
</script>

<style scoped>
.yz {
  display: flex;
}
.l-bu {
  background-color: #982822;
  color: white;
  font-size: 30px;
}
.l-bu:hover {
  background-color: white;
  color: rgb(61, 56, 56);
}
button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
}
.login {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../public/ll.jpeg");
  background-size: cover;
}

.yzm {
  display: flex;
  justify-content: space-between;
}

.yz .el-input {
  width: 80%;
}

.el-input .el-input__inner {
  font-size: 20px;
  font-weight: bold;
  outline:none
}

.el-input__inner::-webkit-input-placeholder {
  font-size: 15px;
  font-weight: bold;
}
  
.form {
  width: 35%;
  height: 80%;
  background-color: #506ca15e;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 35px 0 35px;
  box-shadow: 1px 2px 10px;
  border-radius: 10px;
  position: relative;
}

.box {
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 5px; ;
}

.form1 {
  width: 35%;
  height: 60%;
  background-color: rgb(255, 254, 252);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 35px 0 35px;
  box-shadow: 1px 2px 10px;
  border-radius: 10px;
  position: relative;
}

/* .phone{
 
} */
h1{
  color: white;
}
.img1 {
  display: block;
}

.img2 {
  display: block;
  width: 30px;
  height: 30px;
}

.img3 {
  display: block;
  width: 30px;
  height: 30px;
}

.form1 .img4 {
  display: block;
  width: 30px;
  height: 30px;
}

.form1 .img1 {
  display: block;
  width: 30px;
  height: 30px;
}

.form1 .img2 {
  display: block;
  width: 30px;
  height: 30px;
}

.form1 .img3 {
  display: block;
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 1000px) {
  .form {
    width: 70%;
    height: 70%;
  }
}

@media screen and (max-width: 600px) {
  .form {
    width: 80%;
    height: 70%;
  }
}

@media screen and (max-height: 500px) {
  .form {
    width: 80%;
    height: 90%;
  }
}
</style>