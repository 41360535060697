<style  scoped>
.loadbox {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  margin-top: 50px;
}
.loadbox-1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  background-color: rgba(240, 248, 255, 0.745);
  padding: 0px 5px 20px 5px;
  border-radius: 10px;
}
.load-1 {
  width: 98%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
.load-2 {
  width: 100%;
  height: auto;
  background-color: rgba(243, 227, 203, 0.857);
  box-shadow: 1px 1px 5px rgba(0, 0, 1, 0.259);
  padding: 10px 0;
}
.title {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
p {
  word-wrap: break-word;
  word-break: break-all;
}
.line {
  background-color: #093b9723;
  width: 2px;
  height: 60px;
}
.apply-1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  padding: 10px 0 10px 0;
}
.load-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.load-4 {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  width: 80%;
  text-align: left;
}

.load-4 p {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  width: 80%;
  text-align: left;
}

@media screen and (max-width: 800px) {
  .loadbox-1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: left;
  }
  .load-4 {
    width: 100%;
  }

  .load-4 p {
    width: 100%;
  }
}
/* ::v-deep .el-step__icon.is-text {
  border-radius: 50%;
  border: none;
}
::v-deep .el-step__title.is-process {
  font-weight: 500;
  color: #303133;
}

::v-deep .el-step__head.is-finish {
  color: #303133;
  border-color: #303133;
} */
.flag0 {
  color: #c0c4cc;
}
.flag1 {
  color: #67c23a;
}
.flag2 {
  color: red;
}
.flag0 .el-step__main .el-step__title {
  color: #243649 !important;
}
::v-deep .el-step__title.is-process {
  font-weight: 700;
  color: #67c23a !important;
}
::v-deep .el-step__head.is-process {
  color: #67c23a;
  border-color: #67c23a;
}
</style>

<template>
  <div class="loadbox">
    <div class="loadbox-1" v-if="loadvisible">
      <div class="load-1">
        <p style="font-size:20px;font-weight: 700;">. Loan progress</p>
        <div class="load-2">
          <div class="title">
            <p style="font-size:15px">Numbering:{{loadinfo.code}}</p>
            <p style="font-size:15px">{{loadinfo.time}}</p>
          </div>
          <div style="padding:10px 0">
            <el-steps :active="active" align-center finish-status="success">
              <el-step title="Submit complete" :status="step0" description></el-step>
              <el-step title="Under review" :status="step1" description></el-step>
              <el-step title="Approve" :status="step2" description></el-step>
              <el-step title="Successful withdrawal" status="wait" description></el-step>
            </el-steps>
          </div>
          <div>
            <div class="apply-1">
              <div>
                <p style="width:100%;font-size:25px;font-weight:700;">
                  <span style="  color:red;
">{{loadinfo.borrowamount}}₱</span>
                </p>
                <p style="font-weight:700;font-size:20px">Loan amount</p>
              </div>
              <div class="line"></div>
              <div>
                <p style="width:100%;font-size:25px;font-weight:700;">{{loadinfo.term}} month</p>
                <p style="font-weight: 700;font-size:20px">Borrowing time</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="load-1">
        <p style="font-size:20px;font-weight: 700;">.Situation statement</p>
        <div class="load-2 load-3">
          <p style="padding:0 10px;font-size:20px;font-weight: 700;">
            Describe：
            <span :class="flag1">{{loadinfo.info}}</span>
          </p>
          <p style="padding:0px 15px 20px 15px;font-size:20px;font-weight: 700;">{{loadinfo.state}}</p>
        </div>
      </div>
      <div class="load-1">
        <p style="font-size:20px;font-weight: 700;">. Loan Details</p>
        <div class="load-2">
          <div class="load-4">
            <p style="font-size:20px;">Order number</p>
            <p style="font-size:25px;font-weight: 700;">{{loadinfo.code}}</p>
          </div>
          <div class="load-4">
            <p style="font-size:20px;">Loan amount</p>
            <p style="font-size:25px;font-weight: 700;">{{loadinfo.borrowamount}}₱</p>
          </div>
          <div class="load-4">
            <p style="font-size:20px">Borrowing time</p>
            <p style="font-size:25px;font-weight: 700;">{{loadinfo.term}} month</p>
          </div>
          <div class="load-4">
            <p style="font-size:20px;">Monthly payment</p>
            <p style="font-size:25px;font-weight: 700;">{{syhk}}₱</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      active: 3,
      flag1: "flag0",
      step0: "wait",
      step1: "wait",
      step2: "wait",
      accountBlance: 0,
      visible: false,
      loadvisible: false,
      loanAmount: "",
      loadinfo: {
        borrowamount: 0,
        code: "0",
        info: null,
        state: null,
        term: 0,
        time: "0",
        flag: 0
      },
      syhk: ""
    };
  },
  mounted() {
    this.getLoad();
  },
  methods: {
    getLoad() {
      axios
        .get(
          `borrowingSys/borrowing/borro/selectstate?uid=${+sessionStorage.getItem(
            "token"
          )}`,
          {
            withCredentials: true
          }
        )
        .then(res => {
          //console.log(res.data);
          if (res.data != null) {
            if (res.data.data.syhk != null) {
              this.loadinfo = res.data.data.pbsetate;
             // this.loadinfo.flag =1;
              if (this.loadinfo.flag == -1) {
                this.flag1 = "flag2";
                this.step0 = "error";
                this.active = 1;
                this.loadinfo.info = "Not passed";
                // this.loadinfo.state="Incomplete information"
              }
              if (this.loadinfo.flag == 0) {
                this.flag1 = "flag2";
                this.step0 = "process";
                this.flag1 = "flag0";
                this.step1 = "process";
                this.active = 2;
                this.loadinfo.info = "Under review";
                //  this.loadinfo.state="Your order is currently under review"
              }
              if (this.loadinfo.flag == 1) {
                this.step0 = "process ";
                this.step1 = "process ";
                this.flag1 = "flag1";
                this.step2 = "process";
                this.active = 3;
                this.loadinfo.info = "Approve";
                // this.loadinfo.state="Your review has been approved"
              }

              this.syhk = res.data.data.syhk;
              this.loadvisible = true;
            }
          }
          //console.log(res.data);
        })
        .catch(error => {
          // ////////console.log(error);
        });
    }
  }
};
</script>

